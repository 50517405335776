
import { mapGetters, mapState, mapMutations } from 'vuex'
import axios from 'axios'
import { getDictWP } from '~/utils/butterUtils'
import { FAKE_STATES,
         getVFAParameters } from '~/utils/VFAParameters'
import snarkdown from 'snarkdown'
import { event } from 'vue-gtag'

export default {
  data () {
    return {
      customEmail: '',
      message: '',
      subject: '',
      subject_test_suffix: ' - TEST - NOT sent to LEO',
      isMailing: false,
      dropFiles: [],
      reqDoc: '',
      sendWithoutDocs: false
    }
  },
  props: ['value',
          'fpca',
          'pdfFpca',
          'attachmentRequired',
          'instructionsObject',
          'isStateSpecial',
          'stateRules',
          'dict'
  ],
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    showTestData () {
      return this.$store.state.showTestData
    },
    isProductionSite () {
      return process.env.stage === 'prod'
    },
    isStudentSite () {
      return process.env.isStudentSite === 'true' || this.$store.state.isSimulatedStudentSite
    },
    voterMessage () {
      let msg =
        this.getDictWP(this.dict.D44,
          {
            leoEmail: this.leoEmail,
            leoName: this.leoName,
            leoPhone: this.leoPhone
          })
      msg = encodeURI(msg).replace(/\%5Cn/gi, '\%0A').replace(/\%5Ct/gi, '\%09')
      return decodeURI(msg)
    },
    formEmail: {
      get () {
        return this.email || this.customEmail
      },
      set (value) {
        this.customEmail = value
      }
    },
    currentRequest () {
      return this.requests[this.currentRequestIndex]
    },
    firstName () {
      return this.currentRequest && this.currentRequest.firstName
        ? this.currentRequest.firstName
        : ''
    },
    lastName () {
      return this.currentRequest && this.currentRequest.lastName
        ? this.currentRequest.lastName
        : ''
    },
    email () {
      return this.currentRequest && this.currentRequest.email
        ? this.currentRequest.email.toString()
        : ''
    },
    tel () {
      return this.currentRequest && this.currentRequest.tel
        ? this.currentRequest.tel
        : ''
    },
    leoName () {
      return this.currentRequest.leo && this.currentRequest.leo.n
        ? this.currentRequest.leo.n
        : ''
    },
    leoEmail () {
      return this.currentRequest.leo && this.currentRequest.leo.e
        ? this.currentRequest.leo.e
        : ''
    },
    leoPhone () {
      return this.currentRequest.leo && this.currentRequest.leo.p
        ? `+1 ${this.currentRequest.leo.p}`
        : ''
    },
    leoFax () {
      return this.currentRequest.leo && this.currentRequest.leo.f
        ? `+1 ${this.currentRequest.leo.f}`
        : ''
    },
    leoAddress1 () {
      return this.currentRequest && this.currentRequest.leo.a1
        ? this.currentRequest.leo.a1
        : ''
    },
    leoAddress2 () {
      return this.currentRequest && this.currentRequest.leo.a2
        ? this.currentRequest.leo.a2
        : ''
    },
    leoCity () {
      return this.currentRequest && this.currentRequest.leo.c
        ? this.currentRequest.leo.c
        : ''
    },
    leoJurisdiction () {
      return this.currentRequest && this.currentRequest.leo.j
        ? this.currentRequest.leo.j
        : ''
    },
    leoState () {
      return this.currentRequest && this.currentRequest.leo.s
        ? this.currentRequest.leo.s
        : ''
    },
    leoZIPCode () {
      return this.currentRequest && this.currentRequest.leo.z
        ? this.currentRequest.leo.z
        : ''
    },
    fromName () {
      return `${this.firstName} ${this.lastName}`
    },
    voterMailto () {
      let recipient = this.isProductionSite ? this.leoEmail : this.TEST_EMAIL_ADDRESS
      let subject = this.isProductionSite ? this.subject : this.subject+this.subject_test_suffix
      return recipient+"?subject="+subject+"&body="+encodeURIComponent(this.message)
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    TEST_EMAIL_ADDRESS () {
      return FAKE_STATES.includes(this.leoState) ? this.leoEmail : this.VFAParameters.TEST_EMAIL_ADDRESS
    },
    ...mapState({
      currentRequestIndex: state => state.requests.currentRequest,
      requests: state => state.requests.requests
    }),
    ...mapGetters('requests', ['getCurrent']),
  },
  methods: {
    md: function (md) {
      return snarkdown(md)
    },
    attachReqDoc (files) {
      let vm = this
      if (
        files &&
        files.length > 0 &&
        files[0].name &&
        /\.(jpe?g|png|gif|pdf)$/i.test(files[0].name)
      ) {
        var reader = new FileReader()

        reader.addEventListener(
          'load',
          function () {
            // console.log(reader, this)
            vm.reqDoc = this.result
            vm.sendWithoutDocs = false
          },
          false
        )

        reader.readAsDataURL(files[0])
      }
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
    sendEmail () {
      if (this.attachmentRequired && !this.reqDoc && !this.sendWithoutDocs) {
        this.$buefy.dialog.confirm({
          title: this.dict.D34,
          message: this.dict.F49,
          cancelText: this.dict.I12,
          confirmText: this.dict.F50,
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.sendWithoutDocs = true
            this.sendEmail()
          }
        })
      } else if (
        !this.formEmail.trim() ||
        !/(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(this.customEmail)
      ) {
        if (this.$refs.userEmail) {
          this.$refs.userEmail.checkHtml5Validity()
        }
      } else {
        this.isMailing = true
        let headers = {}
        headers['Content-Type'] = 'application/json'
        headers['Accept'] = 'application/json'
        let body = {
          subject: this.isProductionSite ? this.subject : this.subject+this.subject_test_suffix,
          voterEmail: this.formEmail,
          voterMessage: this.voterMessage,
          leoMessage: this.message,
          leoName: (this.leoName || '').replace(/[^A-Za-z\s]/g, ''),
          leoEmail: this.isProductionSite ? this.leoEmail : this.TEST_EMAIL_ADDRESS,
          pdfFpca: this.pdfFpca ? this.pdfFpca.toString() : null,
          reqDoc: this.reqDoc ? this.reqDoc.toString() : null,
          firstName: this.firstName,
          lastName: this.lastName
        }

        if (!this.isProductionSite) {
          console.log('voterName='+body.firstName+' '+body.lastName
                +'\n'+'subject='+body.subject
                +'\n'+'voterEmail='+body.voterEmail
                +'\n'+'voterMessage='+body.voterMessage
                +'\n'+'leoName='+body.leoName
                +'\n'+'leoEmail='+body.leoEmail
                +'\n'+'leoMessage='+body.leoMessage
          )
        }

        axios
          .post(
            `/api/mailer${this.isProductionSite ? '' : '/dev'}`,
            body,
            {
              headers: { 'Content-Type': 'application/json' }
            }
          )
          .then(response => {
            // console.log(response.data)
            this.isMailing = false
            this.$store.dispatch('requests/updateBackendFormEmailed')
            event('fpca_emailed')
            event('fpca_complete')
            this.$router.push(this.localePath('dashboard'))
            this.$buefy.toast.open({
              message: this.getDictWP(this.dict.G33, {
                email: this.formEmail
              }),
              type: 'is-success'
            })
          })
          .catch(error => {
            console.log(error)
            this.isMailing = false
            this.$buefy.dialog.alert({
              title: this.dict.G10,
              message: this.dict.G09,
              confirmText: this.dict.I25,
              type: 'is-danger',
              hasIcon: true,
              icon: 'error',
              iconPack: 'fas'
            })
          })
      }
    },
    cancelSendEmail () {
      this.$buefy.toast.open({
        message: 'Your submission has been cancelled.',
        type: 'is-info',
        duration: 5000
      })
      this.$router.push(
        this.localePath({ name: 'request-sign-submit-nextsteps' })
      )
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
    ...mapMutations('requests', ['update']),
  },
  mounted () {
    this.subject = this.dict.M26
    let LEOmessage = this.getDictWP(this.dict.C44,
      { "firstName": this.firstName,
        "lastName": this.lastName,
        "formEmail": this.formEmail,
        "tel": this.tel,
        "leoName": this.leoName,
        "leoAddress1": this.leoAddress1,
        "leoAddress2": this.leoAddress2,
        "leoCity": this.leoCity,
        "leoJurisdiction": this.leoJurisdiction,
        "leoState": this.leoState,
        "leoZIPCode": this.leoZIPCode,
        "leoEmail": this.leoEmail,
        "leoPhone": this.leoPhone,
        "leoFax": this.leoFax,
      })
    this.message = decodeURI(encodeURI(LEOmessage).replace(/\%5Cn/gi, '\%0A').replace(/\%5Ct/gi, '\%09'))
  }
}
