
import { mapGetters, mapMutations } from 'vuex'
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'
import { getNewVfaDate } from '~/utils/butterUtils'
import { getVFAParameters } from '~/utils/VFAParameters';

export default {
  name: 'EmailReminder',
  mixins: [fieldLabelAndTooltip],
  props: [
    'label',
    'fieldName',
    'instructions',
    'toolTipTitle',
    'toolTipContent',
    'dict',
    'isSubmitByEmailNow'
  ],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      },
      isoptin: null,
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    currentRequest () {
      return this.$store.getters["requests/getCurrent"] || {};
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
  },
  methods: {
    set_email_reminder () {
      const days_to_wait = this.isSubmitByEmailNow ? this.VFAParameters.EMAIL_REMINDER_DAYS_TO_WAIT_EMAIL_NOW : this.VFAParameters.EMAIL_REMINDER_DAYS_TO_WAIT_NOT_EMAIL_NOW
      const rDate = getNewVfaDate().getTime()+days_to_wait*1000*60*60*24
      const reminderDate = this.isoptin ? new Date(rDate).toISOString() : "1970-01-01T00:00:00.000Z"

      this.$store.commit('requests/update', { emailReminder: { optin: this.isoptin, reminderDate: reminderDate } })
    },
    set_optin (value) {
      this.isoptin = value
      this.set_email_reminder ()
    },
    ...mapGetters('requests', ['getCurrent']),
    ...mapMutations('requests', ['update'])
  },
  mounted () {
    if (this.currentRequest.emailReminder === undefined) {
      this.$store.commit('requests/update', { emailReminder: { optin: false, reminderDate: "1970-01-01T00:00:00.000Z" } })
    } else {
      this.isoptin = this.currentRequest.emailReminder.optin
    }
  },
  watch: {
    isSubmitByEmailNow () {
      this.set_email_reminder ()
    }
  },
}
