import { render, staticRenderFns } from "./SubmitComposeMessage.vue?vue&type=template&id=5f38b3fc"
import script from "./SubmitComposeMessage.vue?vue&type=script&lang=js"
export * from "./SubmitComposeMessage.vue?vue&type=script&lang=js"
import style0 from "./SubmitComposeMessage.vue?vue&type=style&index=0&id=5f38b3fc&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {At: require('/opt/build/repo/components/At.js').default})
