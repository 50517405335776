
export default {
  props: [ 'value',
           'affirm',
           'dict'
  ],
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    }
  }
}
